<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { transmitter } from '~/js/global_stores/base.js';
	import { getTemplate } from '../../dcts/template.js';
	import moment from 'moment';

	let previewDct = '';

	onMount(() => {
		moment.locale('ru');
	});

	const templateObserver = async (template) => {
		const p = await getTemplate(template)
		previewDct = p.preview
	}

	$: templateObserver($transmitter.dct?.template)
	// $: previewDct = getTemplate($transmitter.dct?.template).preview;
</script>

<!-- eslint-disable-next-line svelte/no-at-html-tags -->
{@html previewDct}
