<!-- @format -->
<script>
	import { dct, currentData, requiredFieldsFilled, commonParams } from '~/svelte/dcts/stores/stores';
	import { procedure } from 'base_stores';
	import AutosaveDatepicker from '~/svelte/components/ui/autosave_datepicker.svelte';
	import moment from 'moment/moment';

	let hintData;

	$: if ($dct.form_fields.includes('startDateOfRequestedInformationPeriod')) {
		$requiredFieldsFilled.startDateOfRequestedInformationPeriod = !!startDateOfRequestedInformationPeriod.value;
	}

	$: if (!$currentData.startDateOfRequestedInformationPeriod && !$procedure?.bankruptcy_petition_date) {
			hintData = null;
		} else if (moment($currentData.startDateOfRequestedInformationPeriod).isSame(moment($procedure?.bankruptcy_petition_date).subtract(3, 'years'))) {
			hintData = { linkText: 'Дата принятия судом заявления', additionalText: ' - 3 года' }
		} else {
			hintData = { freeMessage: 'Изменено пользователем' }
		}

	$: startDateOfRequestedInformationPeriod = {
		...$commonParams,
		required: true,
		attribute: 'start_date_of_requested_information_period',
		label: 'Начало периода запрашиваемых сведений',
		value: $currentData.startDateOfRequestedInformationPeriod,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		hintData,
	};
</script>

<AutosaveDatepicker {...startDateOfRequestedInformationPeriod} />
