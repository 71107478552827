<!-- @format -->
<script>
	import { onMount, createEventDispatcher } from 'svelte';
	import { fetchGet, fetchPut, fetchPost } from '~/js/utils/fetch_helpers.js';
	import { transmitter, procedure, options, bankruptIsPerson, processing } from '~/js/global_stores/base.js';
	import { substitutions, requiredFieldsFilled, pdfDctData, currentData } from '~/svelte/dcts/stores/stores.js';
	import { isPresent, isBlank } from '~/js/utils/tools.js';
	import FormFields from '~/svelte/dcts/form_fields.svelte';
	import Template from './template.svelte';
	import Modal, { hideModal } from '~/svelte/components/modal.svelte';
	import ErrorMessage from '~/svelte/components/message.svelte';
	import printJS from 'print-js';
	import IconInfo from '~/svelte/_shared/icon-info.svelte';
	import PrintIcon from '~/svelte/_shared/print_icon.svelte';
	import DownloadIcon from '~/svelte/_shared/download_icon.svelte';
	import PdfIcon from '~/svelte/_shared/pdf_icon.svelte';
	import DocxIcon from '~/svelte/_shared/docx_icon.svelte';
	import SwitchBtn from '~/svelte/components/ui/switch_btn.svelte';

	const dispatch = createEventDispatcher();

	export let meeting;
	export let data;
	export let pageResultMessageDisabled;

	let downloadUrl;
	let errors = [];
	let efrsbLoading = false;
	let efrsbResponse = null;
	let switchValue;

	const updateDocument = () => {
		const params = isDraft
			? { dct: { ...$substitutions, status: 'approved', conduct_form: meeting.form }, pdf_data: $pdfDctData }
			: { dct: { status: 'draft' } };

		$processing = true;

		fetchPut(`/api/private/documents/${$transmitter.dct.id}`, params)
			.then(result => {
				$transmitter.dct = { ...result.dct };
				$procedure = { ...result.procedure };
				dispatch('changeResultMessageStatus', $transmitter.dct.status);
				$processing = false;
			})
			.catch(error => {
				errors = error;
				$processing = false;
				throw error;
			});
	};
	const printDocument = () => printJS({ printable: downloadUrl, type: 'pdf', showModal: true });

	onMount(() => {
		$processing = true;

		fetchGet(`/api/private/meetings/${meeting.id}/get_result_message`)
			.then(result => {
				$options = { ...$options, ...result.options };
				$requiredFieldsFilled = {};
				$transmitter.meeting = {
					...meeting,
					questions: [...data.questions],
					participants: [...data.participants],
					bulletins: [...data.bulletins],
					discussions: [], //[...result.discussions],
					forms: [...data.forms],
					reasons: [...data.reasons],
					voting_meeting_statuses: [...data.voting_meeting_statuses],
					disabled_fields: [
						'meetingVotingQty',
						'meetingVotingSum',
						'meetingForm',
						'meetingFirst',
						'meetingReason',
						'meetingFromAt',
						'meetingVotingStatus',
						'meetingAddress',
						'meetingParticipantVotingProc',
					],
				};
				$transmitter.dct = { ...result.dct };
				$processing = false;
			})
			.catch(error => {
				$processing = false;
				errors = error;
				throw error;
			});
	});

	const sendDraftToEfrsb = async () => {
		efrsbResponse = { note: 'Отправка может занять несколько минут.' };
		errors = [];

		try {
			efrsbLoading = true;
			efrsbResponse = await fetchPost(`/api/private/documents/${$transmitter.dct.id}/send_message_to_efrsb`, {
				message_type: 'meeting_result_message',
				meeting_id: meeting.id,
			});

			$transmitter.meeting = {
				...$transmitter.meeting,
				publication_solutions_number: efrsbResponse.publication_solutions_number,
				publication_solutions_date: efrsbResponse.publication_solutions_date,
			};
			dispatch('publishedSolutionsEFRSB', {
				publication_solutions_number: efrsbResponse.publication_solutions_number,
				publication_solutions_date: efrsbResponse.publication_solutions_date,
			});
		} catch (e) {
			efrsbResponse = null;
			errors = e.message;
			throw e;
		} finally {
			efrsbLoading = false;
		}
	};

	$: downloadUrl = `/api/private/meetings/${meeting.id}/download_result_message?type=pdf`;
	$: isDraft = meeting.result_message_status == 'draft'; //isPresent($transmitter.dct) && $transmitter.dct.status == 'draft'
	$: formReady = Object.values($requiredFieldsFilled).every(value => value);
	$: missingInnAndSnils = $currentData && isBlank($currentData.bankruptInn) && isBlank($currentData.bankruptSnils);
	$: notPossibleSendToEfrsb = isDraft || !$procedure.arbitr_manager_efrsb_account_valid || missingInnAndSnils || efrsbLoading;
	$: sendToEfrsbTooltip = isDraft
		? 'Сначала утвердите документ'
		: !$procedure.arbitr_manager_efrsb_account_valid
			? 'Укажите учётные данные ЕФРСБ на странице АУ'
			: missingInnAndSnils
				? `Для отправки сообщения необходимо заполнить ИНН${$bankruptIsPerson ? ' или СНИЛС' : ''} должника`
				: null;
</script>

<div class="document-form">
	<div class="toolbar">
		<div class="flex-slot">
			{#if meeting.status != 'canceled'}
				<ul class="nav navbar-top-links tools-bar flex-shrink-slot flex-nowrap">
					<li class="m-r-sm" style="display: flex;">
						<div class="raf__with-tooltip">
							<button
								class="btn btn-sm mt-1 mt-sm-0"
								class:btn-primary={!pageResultMessageDisabled}
								class:btn-default={pageResultMessageDisabled}
								class:btn-warning={!isDraft}
								disabled={(isDraft && !formReady) || pageResultMessageDisabled || $processing}
								on:click={updateDocument}
							>
								{isDraft ? 'Утвердить' : 'Изменить'}
								{#if $processing}
									<span class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
								{/if}
							</button>
							{#if pageResultMessageDisabled}
								<div class="raf__tooltip">Недоступно. Сначала утвердите предыдущую вкладку</div>
							{/if}
						</div>
						<div class="btn btn-sm raf__with-tooltip">
							<IconInfo />
							<div class="raf__tooltip">Фиксирует документ в pdf, защищает его от случайных изменений</div>
						</div>
					</li>
					<li class="m-l-md" style="display: flex;">
						<div class="raf__with-tooltip">
							<button
								class="btn btn-sm mt-1 mt-sm-0"
								class:btn-warning={!notPossibleSendToEfrsb}
								class:btn-default={notPossibleSendToEfrsb}
								disabled={notPossibleSendToEfrsb || (isDraft && !formReady) || pageResultMessageDisabled || $processing}
								on:click={sendDraftToEfrsb}
								><span class="hidden-mobile">Отправить в </span>ЕФРСБ
								<span class="spinner-border spinner-border-sm ml-1" class:hidden={!efrsbLoading}></span>
							</button>
							{#if sendToEfrsbTooltip}
								<div class="raf__tooltip">{sendToEfrsbTooltip}</div>
							{/if}
						</div>
						<div class="btn btn-sm raf__with-tooltip">
							<IconInfo />
							<div class="raf__tooltip">Отправит сообщение в ЕФРСБ</div>
						</div>
					</li>
					{#if efrsbResponse}
						<li class="m-l-sm">
							<span
								class:text-success={isPresent(efrsbResponse.info)}
								class:text-danger={isPresent(efrsbResponse.errors)}
								class:text-note={isPresent(efrsbResponse.note)}
							>
								{isPresent(efrsbResponse.info) ? efrsbResponse.info : isPresent(efrsbResponse.errors) ? efrsbResponse.errors : efrsbResponse.note}
							</span>
						</li>
					{/if}
				</ul>
				<ul class="nav navbar-top-links tools-bar flex-grow-slot">
					<li class="m-l-sm">
						{#if isDraft}
							<DownloadIcon disabled={true} />
						{:else}
							<a download href={downloadUrl}>
								<DownloadIcon />
							</a>
						{/if}
					</li>
					<li class="m-l-sm m-r-xs">
						{#if isDraft}
							<PrintIcon disabled={true} />
						{:else}
							<span style="cursor: pointer;" role="button" tabindex="0" on:click={printDocument} on:keypress|stopPropagation>
								<PrintIcon />
							</span>
						{/if}
					</li>
				</ul>
			{/if}
		</div>
	</div>
	<div class="content-body footer-margin">
		<div class="row" style="height: calc(100vh - 92px); margin-right: 0;">
			<div class="col-sm-5" class:hidden-mobile={!switchValue} style="overflow: auto; height: 100%; padding-right: 0;">
				<div class="overlay">
					<ErrorMessage message={errors} on:click={() => (errors = [])} />
					{#if $processing}
						загружается...
					{:else}
						<FormFields disabledForm={pageResultMessageDisabled || $processing} on:change />
					{/if}
				</div>
			</div>
			<div class="col-sm-7" class:hidden-mobile={switchValue} style="overflow: auto; height: 100%; padding-right: 0;">
				<div class="preview">
					<div class="preview__page">
						{#if $processing}
							загружается...
						{:else}
							<Template />
						{/if}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<SwitchBtn bind:switchValue />

<Modal modalId="print-modal" size="small">
	<div slot="footer" class="d-flex justify-content-around">
		<a
			download
			href={'/api/private/meetings/' + meeting.id + '/download_protocol?type=pdf'}
			class="download-icon btn btn-primary btn-outline"
			on:click={() => hideModal('print-modal')}
		>
			<PdfIcon />
			<span class="m-l-xs">pdf</span>
		</a>
		<a
			download
			href={'/api/private/meetings/' + meeting.id + '/download_protocol?type=docx'}
			class="download-icon btn btn-primary btn-outline"
			on:click={() => hideModal('print-modal')}
		>
			<DocxIcon />
			<span>docx</span>
		</a>
	</div>
</Modal>

<style lang="scss">
	.document-form {
		width: 100%;

		.toolbar {
			border-bottom: 1px solid #e7eaec;
			padding-bottom: 10px;
			padding-left: 15px;
			margin-left: -15px;

			.navbar-top-links li a {
				padding: 0px;
				min-height: auto;
			}
		}
		.content-body {
			background-color: #f3f3f4;
			.overlay,
			.preview {
				border-left: none;
				border-top: none;
			}
		}
	}

	.preview {
		flex-direction: column;
		align-items: center;
		color: #000;
		box-shadow:
			0 2px 2px 0 rgba(0, 0, 0, 0.14),
			0 3px 1px -2px rgba(0, 0, 0, 0.2),
			0 1px 5px 0 rgba(0, 0, 0, 0.12);
		border: 1px solid #e7eaec;
	}

	.preview__page {
		--aspect-ratio: 210 / 297;
		box-sizing: border-box;
		background-color: #fff;
		padding: 7.14% 7.14% 7.14% 9.52%;
	}

	.download-icon {
		padding: 10px;
		min-height: unset;
		fill: #1ab394;
		&:hover {
			fill: #fff;
		}
	}
</style>
