<!-- @format -->
<script>
	import { currentData, commonParams, dct } from '~/svelte/dcts/stores/stores';
	import Checkbox from '~/svelte/components/ui/autosave_checkbox.svelte';

	$: includeAppendices = {
		...$commonParams,
		attribute: 'include_appendices',
		label: 'Добавить приложения',
		value: $currentData.includeAppendices,
		text: 'Да, добавить',
	};
</script>

<Checkbox {...includeAppendices} />
