/** @format */

import { isPresent, isPresence } from 'utils/tools';

export const fieldWrapper = (value, isDraft, isRequired = false, tag = 'span') => {
	if (isDraft) {
		if (isPresent(value)) {
			return '<' + tag + ' class="success">' + value + '</' + tag + '>';
		} else if (isRequired) {
			return `<span class="required-empty">${isDraft ? '&nbsp;' : ''}</span>`;
		} else {
			return `<span class="empty">${isDraft ? '&nbsp;' : ''}</span>`;
		}
	} else {
		if (isPresent(value)) {
			return '<' + tag + '>' + (value === 0 ? 0 : isPresence(value)) + '</' + tag + '>';
		} else {
			return '';
		}
	}
};

export const fieldWrapperDiv = (value, isDraft, isRequired = false) =>
	fieldWrapper(value, isDraft, isRequired, 'div style="font-size: 11pt; font-family: Arial;"');
