<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { procedure, transmitter } from 'base_stores';
	import { dct, commonParams, currentData } from '~/svelte/dcts/stores/stores';
	import AutosaveMultipleSelect from '~/svelte/components/ui/autosave_multiple_select.svelte';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';
	import PublicationFedresursNumber from './publications/fedresurs/number.svelte';
	import PublicationFedresursDate from './publications/fedresurs/date.svelte';
	import PublicationFedresursCopiesNumber from './publications/fedresurs/copies_number.svelte';
	import PublicationFedresursPagesNumber from './publications/fedresurs/pages_number.svelte';
	import PublicationNewspaperNumber from './publications/newspaper/number.svelte';
	import PublicationNewspaperDate from './publications/newspaper/date.svelte';
	import PublicationNewspaperCopiesNumber from './publications/newspaper/copies_number.svelte';
	import PublicationNewspaperPagesNumber from './publications/newspaper/pages_number.svelte';
	import { fetchGet } from 'utils/fetch';
	import moment from 'moment/moment';

	let appendixOptions = [
		{ id: 'judicial_act', text: 'Судебный акт' },
		{ id: 'publication_kommersant', text: 'Публикация ”Коммерсант”' },
		{ id: 'publication_fedresurs', text: 'Публикация ”Федресурс”' },
		{ id: 'arbitr_manager_passport', text: 'Паспорт АУ' },
		{ id: 'bankrupt_passport', text: 'Паспорт должника' },
		{ id: 'bankrupt_spouse_passport', text: 'Паспорт супруга(и)' },
		{ group: 'Входящие документы', subOptions: [] },
		{ group: 'Исходящие документы', subOptions: [] },
		{ id: 'user_text_1', text: 'Пользовательский текст 1' },
		{ id: 'user_text_2', text: 'Пользовательский текст 2' },
		{ id: 'user_text_3', text: 'Пользовательский текст 3' },
	];

	let mounted = false;
	let reportingPeriodStart;
	let reportingPeriodFinish;
	let forceSave = false;
	let answers = [];
	let dcts = [];
	let value = [];

	const answerName = answer => answer.additions?.name || `${answer.name} ${answer.reply_date ? 'от ' + moment(answer.reply_date).format('DD.MM.YYYY') : ''}`;

	const loadOptions = async () => {
		value = $dct.substitutions?.appendices || []

		let response = await fetchGet('/api/private/answers', {
			procedure_id: procedureId,
			reporting_period_start: null, //$currentData.reportingPeriod[0],
			reporting_period_finish: null, //$currentData.reportingPeriod[1],
		})
		answers = [...response.answers, ...response.answers_other, ...response.answers_requirement]
			.sort((a, b) => answerName(a).localeCompare(answerName(b)));
		appendixOptions[6].subOptions = answers.map(a => ({ id: `answer_${a.id}`, text: answerName(a), date: a.reply_date }));

		response = await fetchGet('/api/private/documents', {
			dct_category: 'outgoing',
			procedure_id: procedureId,
			reporting_period_start: null, //$currentData.reportingPeriod[0],
			reporting_period_finish: null, //$currentData.reportingPeriod[1],
		})
		dcts = response.dcts.sort((a, b) => a.out_short_name.localeCompare(b.out_short_name));
		appendixOptions[7].subOptions = dcts.map(d => ({ id: `outgoing_${d.id}`, text: d.out_short_name, date: d.date }));

		if ($dct?.category == 'reports') { refreshValue() }
	};

	const setValue = ({detail: response}) => {
		value = response?.dct?.substitutions?.appendices || []
	}

	const refreshValue = () => {
		let newAnswersOptions = []
		let newDctsOptions = []

		if (forceSave || !value.filter(v => v.startsWith('answer_')).length) {
			newAnswersOptions = appendixOptions[6].subOptions
				.filter(a => (moment(a.date).isBetween(reportingPeriodStart, reportingPeriodFinish, undefined, '[]')))
				.map(a => a.id)
			value = value.filter(v => !v.startsWith('answer_')).concat(newAnswersOptions)
		}

		if (forceSave || !value.filter(v => v.startsWith('outgoing_')).length) {
			newDctsOptions = appendixOptions[7].subOptions
				.filter(d => (moment(d.date).isBetween(reportingPeriodStart, reportingPeriodFinish, undefined, '[]')))
				.map(d => d.id)
			value = value.filter(v => !v.startsWith('outgoing_')).concat(newDctsOptions)
		}

		forceSave = true
	}

	$: procedureId = $procedure?.id;

	$: if ($currentData.reportingPeriod[0] != reportingPeriodStart || $currentData.reportingPeriod[1] != reportingPeriodFinish) {
		const initPeriod = !reportingPeriodStart || !reportingPeriodFinish
		reportingPeriodStart = $currentData.reportingPeriod[0]
		reportingPeriodFinish = $currentData.reportingPeriod[1]

		if (!initPeriod && $dct?.category == 'reports') {
			forceSave = true

			refreshValue()
		}
	}

	$: formFields = {
		appendices: {
			...$commonParams,
			name: 'appendices',
			attribute: 'appendices',
			label: 'Приложения',
			options: appendixOptions,
			disabled: $commonParams.disabled || $dct.status == 'approved',
			value,
		},
		userText: {
			...$commonParams,
			name: 'user_text',
			placeholder: 'Введите текст приложения',
		},
	};

	onMount(() => {
		if ($transmitter.dct?.id) { loadOptions() }

		mounted = true;
	});
</script>

{#if mounted && ($dct?.category != 'reports' || $currentData.includeAppendices)}
	<AutosaveMultipleSelect {...formFields.appendices} bind:forceSave on:update={setValue} />
{/if}

{#if value && value.length > 0}
	{#if value.includes('publication_kommersant') && !($dct?.category == 'reports' && $dct?.kind == 'productive')}
		<PublicationNewspaperNumber />
		<PublicationNewspaperDate />
		<PublicationNewspaperCopiesNumber />
		<PublicationNewspaperPagesNumber />
	{/if}
	{#if value.includes('publication_fedresurs') && !($dct?.category == 'reports' && $dct?.kind == 'productive')}
		<PublicationFedresursNumber />
		<PublicationFedresursDate />
		<PublicationFedresursCopiesNumber />
		<PublicationFedresursPagesNumber />
	{/if}
	{#each value as item}
		{#if typeof item === 'string' && item.startsWith('user_text_')}
			<AutosaveInput {...formFields.userText} attribute={item} label={appendixOptions.find(o => o.id === item).text} value={$dct?.substitutions[item] || ''} />
		{/if}
	{/each}
{/if}
