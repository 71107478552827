/** @format */

import { get } from 'svelte/store';
import { transmitter as _transmitter, options } from 'base_stores';
import {
	actualMeasures, pdfDctData,
	currentData as _currentData,
	requiredFieldsFilled as _requiredFieldsFilled,
	creditorsRequirements as _creditorsRequirements,
	allPossessions as _allPossessions,
} from './stores/stores';
import { tablesData as _tablesData } from './stores/tables_data';
import { triggers, derivedData as _derivedData } from './stores/analytics';
import {
	reworkingStyles,
	reworkingMeetingStyles,
	clearFields,
	transformOtherFields,
	transformBankruptCategoryFields,
	transformAmountFields,
	transformPercentFields,
	transformRegistrarFields,
	transformSurnameFields,
	transformFormProvidingInformation,
	transformObtainingMethodFields,
	transformChildrenBlock,
	transformSpousesBlock,
	transformIncomeSourcesTable,
	transformMeasuresTextBlock,
	transformSentNotificationsRequestsBlock,
	transformAnswersBlock,
	transformAnswersFnsPfrFssBlock,
	transformAnswersAboutPropertyList,
	transformPersonAssetsTable,
	transformPersonLiabilitiesTable,
	transformPersonResponsibilitiesTable,
	transformPersonPossessionsTable,
	transformArbitrManagerAdjectiveFields,
	transformOptionalParagraph,
	insertBankAccounts,
	transformTriggers,
	transformRunningCostsBlock,
	transformReceiptsBlock,
	transformPayoutsBlock,
	transformReceiptsPayoutBlock,
	transformUniqueFields,
	transformTable,
	transformArbitraryText,
	transformAdditionalArbitraryText,
	transformSignature,
	// transformMeetingClauses,
	transformMeetingQuestions,
	transformMeetingSolutions,
	transformMeetingFields,
	transformAppendices,
	transformBankruptInfo,
} from './transformations';
import Spouse from 'models/Spouse';
import Case from 'case';
import moment from 'moment';
import { isPresent, toArray } from 'utils/tools';

// из-за несоответствия написания названий полей в шаблонах заменяем их по подготовленной карте замен.
// TODO: прийти к тому, чтоб видимое пользователю описание поля в шаблоне не привязывалаось к истинному написанию названия поля,
// тогда исправить написание полей в шаблонах 
const actualizeFields = (template, keys) => {
	const replacements = {
		region_id: 'region',
		realty_location_id: 'realty_location',
		sro_id: 'sro_full_name',
		correspondent_id: 'correspondent_short_name',
		court_id: 'court_full_name',
		appendices: 'appendices_list',
	};

	// заменем тэги соответствующими ключами из содержимого документа в соответствии с картой замен
	for (let key of Object.keys(replacements)) {
		template = template.replace(RegExp(`%%${Case.upper(key, '_')}.*?%%`, 'g'), `%%${Case.upper(replacements[key], '_')}%%`);
		keys[keys.indexOf(key)] = replacements[key];
	}

	return [template, keys]
}

// TODO: сюда из формы передаётся одно поле вида %%...%%. слишком жирно.
// надо переделать обработку шаблона, передавать весь шаблон и не в этот метод, а в getTemplate
/*export const templateFieldSubstitution = (template) => {
	const transmitter = get(_transmitter);
	const dct = transmitter.dct;
	const substitutions = dct.substitutions;
	const isDraft = dct.status === 'draft';
	const derivedData = get(_derivedData);
	const requiredFieldsFilled = get(_requiredFieldsFilled);
	const currentData = get(_currentData);

	// ============================================================================================================
	let keys = [
		...new Set([...dct.form_fields.map(key => Case.snake(key)), ...Object.keys(substitutions), ...Object.keys(derivedData).map(key => Case.snake(key))]),
	];

	[template, keys] = actualizeFields(template, keys);

	let templates = { preview: template, pdfData: template };
	// ============================================================================================================

	if (/%%SPOUSES%%/.test(templates.preview)) {
		const spouses = currentData.spousesIds ? (transmitter.spouses || []).filter(spouse => currentData.spousesIds.includes(spouse.id)) : transmitter.spouses;
		templates = transformSpousesBlock(templates, spouses, isDraft); // это есть в другой ветке
	}

	for (let key of keys) {
		if (key.endsWith('surname')) {
			templates = transformSurnameFields(templates, key, substitutions, isDraft, requiredFieldsFilled);
		}
		if (['obtaining_method', 'recipient_of_accounting_statement'].includes(key)) {
			templates = transformObtainingMethodFields(templates, key, substitutions[key]);
		}
		if (/(BANK_ACCOUNTS_LIST%%|BANK_ACCOUNTS_INLINE_LIST%%|%%MAIN_BANK_ACCOUNT%%)/.test(templates.preview)) {
			templates = insertBankAccounts(templates, key, substitutions, isDraft, transmitter);
		}
		if (/judicial_act_date/.test(key)) {
			key = 'judicial_act_date';
		}
		templates = transformRegistrarFields(templates, key, substitutions, isDraft, requiredFieldsFilled);

		// эти 3 строки для сохранения fieldText равным нулю, простое || трактует 0 как false
		let fieldText = currentData[Case.camel(key)];
		if (!fieldText && fieldText !== 0) {
			fieldText = substitutions[key];
		}
		if (!fieldText && fieldText !== 0) {
			fieldText = derivedData[Case.camel(key)];
		}

		if (/percent/.test(key) || /external_factor_/.test(key) || /internal_factor_/.test(key)) {
			templates = transformPercentFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
		}
		if (
			/(amount|balance|monthly_expenses_of_debtor_|debt_to_creditors_from_statement_of_debtor)/.test(key) ||
			[
				'book_property_value',
				'market_property_value',
				'monthly_expenses',
				'debt_to_creditors_from_statement_of_receivable',
				'living_wage_for_bankrupt',
				'free_funds_of_bankrupt',
			].includes(key)
		) {
			templates = transformAmountFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
		}
		if (key == 'meeting_questions') {
			templates = transformMeetingQuestions(templates, isDraft ? fieldText : substitutions[key]);
			continue;
		}
		if (key == 'meeting_solutions') {
			templates = transformMeetingSolutions(templates, isDraft ? fieldText : substitutions[key]);
			continue;
		}
		if (
			[
				'prolongations',
				'prolongations_resolution',
				'renewal_period',
				'completion_date_after_renewal',
				'completion_date_after_renewal_or_completion_date',
			].includes(key)
		) {
			fieldText = transmitter.procedure.prolongations;
		}
		if (/%%SIGNATURE%%/.test(templates.preview) && substitutions.signature) {
			templates = transformSignature(templates, transmitter.procedure.arbitr_manager.signature); // это есть в другой ветке
		}
		if (
			['appendices', 'appendices_list'].includes(key) && /%%(APPENDICES_LIST|APPENDICES)%%/.test(templates.preview) &&
			substitutions.include_appendices) {
			templates = transformAppendices(templates, substitutions, transmitter.procedure, isDraft); // это есть в другой ветке
		}

		templates = transformUniqueFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
		templates = transformOtherFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
		templates = transformOptionalParagraph(templates, substitutions.insert_first_paragraph);
	}

	templates = clearFields(templates, isDraft);

	return templates;
};*/

export const getTemplate = async (template) => {
	console.log('================== getTemplate ==================')
	let transmitter = get(_transmitter)
	const dct = transmitter.dct;

	if (!template) { template = dct?.template }

	if (!template) { return { preview: '', pdfData: '' } }

	const currentData = get(_currentData);
	const requiredFieldsFilled = get(_requiredFieldsFilled);
	const creditorsRequirements = get(_creditorsRequirements);
	const allPossessions = get(_allPossessions);
	const derivedData = get(_derivedData);
	const tablesData = get(_tablesData);
	const bankruptCategories = get(options)?.bankruptCategory;
	const undeclaredDebts = transmitter?.undeclaredDebts;
	const substitutions = dct.substitutions;
	const isDraft = dct.status == 'draft';

	// ============================================================================================================
	//let template = dct.template; // !!
	let keys = [
		...new Set([...dct.form_fields.map(key => Case.snake(key)), ...Object.keys(substitutions), ...Object.keys(derivedData).map(key => Case.snake(key))]),
	];

	[template, keys] = actualizeFields(template, keys);

	// !! - так комментируем новый код на пути объединения схем обработки шаблонов
	template = template
		.replace(/>\s+</g, '><')
		.replace(
			/<p((?!<).)*?>(\s*<span((?!<))*.)*%%APPENDICES%%(((?!>).)*?)(<\/span>)*\s*<\/p>/g,
			`<div class="mce-content-raf-tag-block" data-tag="%%APPENDICES_LIST%%">%%APPENDICES_LIST%%</div>`
		)

	let templates = { preview: template, pdfData: template };
	// ============================================================================================================

	// расставляем фрагменты шаблона в соответствии с триггерами
	templates = transformTriggers(templates, get(triggers));

	// TODO: это кривые триггеры, сделанные не по общей схеме. переделать под общую схему.
	// заменяем конструкцию if-endif у темплейтов собраний
	// templates = transformMeetingClauses(templates, substitutions);

	// удаляем лишнюю стилизацию от MS необходимую для оформления документа как текста электронного письма
	if (transmitter.dct.category == 'meetings') {
		templates = reworkingMeetingStyles(templates);
	} else {
		templates = reworkingStyles(templates);
	}

	// ============================================================================================================
	// !!!!! здесь уже не осталось триггеров, только таблицы, списки и поля !!!!!

	// заполняем таблицы
	// TODO: тупо прогонять заполнение таблицы для каждого ключа - жирно, надо вытаскивать из шаблона ключи талиц и прогонять заполнение только их
	for (let resourceName in tablesData) {
		templates = transformTable(templates, resourceName, tablesData[resourceName], isDraft);
	}

	// заменяем конструкцию if-endif у темплейтов собраний
	// templates = transformMeetingClauses(templates, substitutions); ПОСЛЕ ПРОВЕРКИ УДАЛИТЬ. НАКОНЕЦ-ТО !!!
	// заменяем все поля собрания
	templates = transformMeetingFields(templates, substitutions, isDraft);

	const possessionsSubjectsKeys = Object.keys(allPossessions).filter(key => /^(possessionsSubject|possessionsBankAccounts|possessionsReceivables).*/.test(key));

	for (let key of possessionsSubjectsKeys) {
		if (RegExp(`%%${Case.upper(key, '_')}.*?%%`, 'g').test(templates.preview)) {
			templates = transformPersonPossessionsTable(templates, Case.upper(key, '_'), allPossessions[key]);
		}
	}

	// определяемся с названием АУ для текущего документа: 'временным' или 'арбитражным'
	templates = transformArbitrManagerAdjectiveFields(templates, requiredFieldsFilled);

	// подставляем текст категории банкрота
	templates = transformBankruptCategoryFields(templates, substitutions, bankruptCategories, isDraft, requiredFieldsFilled);

	// TODO: переделать в триггер
	if (substitutions.form_providing_information) {
		templates = transformFormProvidingInformation(templates, substitutions, isDraft);
	}

	// ============================================================================================================
	// !!!!! тупой хардкод, всё в одну кучу, наплевав на тех, кто дальше будет заниматься развитием и поддержкой !!!!!
	// TODO: естественно, всё нужно переписать. если пока не мешает, то позже.
	// заменяем все поля собрания.
	templates = transformMeetingFields(templates, substitutions, isDraft);
	// ============================================================================================================

	// ============================================================================================================
	// !!!!! здесь уже не должно быть и таблиц, только списки и поля !!!!!
	// !!!!! пока списки обрабатываются в куче с полями !!!!!

	let fields = [...new Set(templates.preview.match(/%%[A-Z0-9_]*%%/gm) || [])]
	//	console.log(`fields 0 (${fields.length}): ` + fields)

	// TODO: все ифы из этого перебора надо убрать в transformations.js
	for (let field of fields) {
		if (field == '%%APPENDICES_LIST%%' || field == '%%APPENDICES%%') {
			templates = transformAppendices(field, templates, substitutions, transmitter.procedure, isDraft);
		} else if (field == '%%NUMBER_MEETINGS%%') {
			templates = transformOtherFields(
				templates, 'number_meetings', ((transmitter.meetings && transmitter.meetings.creditors) || []).length, isDraft, requiredFieldsFilled,
			);
		} else if (field == '%%NUMBER_WORKER_MEETINGS%%') {
			templates = transformOtherFields(
				templates, 'number_worker_meetings', ((transmitter.meetings && transmitter.meetings.employees) || []).length, isDraft, requiredFieldsFilled,
			);
		} else if (field == '%%SPOUSES%%') {
			// TODO: к этому моменту spouses не успевают подгрузиться. здесь не лучшее решение, остаётся до обеспечения гарантированной подгрузки spouses.
			// если есть spousesIds, то transmitter.spouses тоже должны быть.
			// если нет spousesIds, то transmitter.spouses при наличии должны отобразиться все, поэтому проверяем прогрузились ли они или просто их нет.
			if (!currentData.spousesIds || !transmitter.spouses && !Array.isArray(transmitter.spouses)) {
				const response = await Spouse.loadSpouses()
				_transmitter.set({ ...get(_transmitter), spouses: response || [] });
				transmitter = get(_transmitter)
			}

			const spouses = currentData.spousesIds ? (transmitter.spouses || []).filter(spouse => currentData.spousesIds.includes(spouse.id)) : transmitter.spouses;
			templates = transformSpousesBlock(templates, spouses, isDraft);
		} else if (field == '%%MINOR_CHILDREN_OF_BANKRUPT%%') {
			templates = transformChildrenBlock(templates, transmitter.children, substitutions.minor_children_of_bankrupt, isDraft);
		} else if (field == '%%ANSWERS%%') {
			templates = transformAnswersBlock(templates, null, transmitter.answers, isDraft);
		} else if (field == '%%SENT_NOTIFICATION_REQUESTS%%') {
			templates = transformSentNotificationsRequestsBlock(templates, null, transmitter.sentNotificationsRequests, isDraft);
		} else if (field == '%%MEASURES_TEXT%%') {
			templates = transformMeasuresTextBlock(templates, get(actualMeasures));
		} else if (field == '%%ANSWERS_FNS_PFR_FSS%%') {
			const answers = transmitter.answers && transmitter.answers.filter(answer => ['fns', 'pfr', 'fss'].includes(answer.correspondent_kind));
			templates = transformAnswersFnsPfrFssBlock(templates, answers, isDraft);
		} else if (field == '%%ANSWERS_ABOUT_PROPERTY%%') {
			const answers =
				transmitter.answers &&
				transmitter.answers.filter(answer =>
					['gibdd', 'mchs', 'gtn', 'mvd', 'fsis', 'favt', 'bti', 'fns', 'rosgvardiya', 'rosreestr'].includes(answer.correspondent_kind),
				);
			templates = transformAnswersAboutPropertyList(templates, answers);
		} else if (field == '%%SIGNATURE%%' && substitutions.signature) {
			templates = transformSignature(templates, transmitter.procedure.arbitr_manager.signature);
		} else if (field == '%%GRAND_TOTAL_RUNNING_COST%%' || /%%.*?_RUNNING_COST_STAGE%%/.test(field)) {
			templates = transformRunningCostsBlock(templates, transmitter);
		} else if (field == '%%RECEIPTS%%') {
			templates = transformReceiptsBlock(templates, transmitter);
		} else if (field == '%%PAYOUTS%%') {
			templates = transformPayoutsBlock(templates);
		} else if (field == '%%RECEIPTS_AND_PAYOUTS%%') {
			templates = transformReceiptsPayoutBlock(templates, transmitter);
		} else if (field == '%%ADDITIONAL_ARBITRARY_TEXT%%') {
			templates = transformAdditionalArbitraryText(templates, substitutions['arbitrary_text'], isDraft);
		} else if (field == '%%BANKRUPT_INFO%%') {
			templates = transformBankruptInfo(templates, transmitter.procedure, isDraft);
		} else if (field == '%%CREDITORS_FIRST_MEETING_DATE%%') {
			templates = transformUniqueFields(
				templates,
				'creditors_first_meeting_date',
				isDraft ? currentData.creditorsFirstMeetingMinutesDate : currentData.creditorsFirstMeetingMinutesDate || '<div style="width: 100%; text-align: center;">-</div>',
				isDraft,
				requiredFieldsFilled,
			);
		} else if (field == '%%CREDITORS_FIRST_MEETING_AGENDA%%') {
			templates = transformOtherFields(
				templates,
				'creditors_first_meeting_agenda',
				isDraft ? currentData.creditorsFirstMeetingAgenda : currentData.creditorsFirstMeetingAgenda || '<div style="width: 100%; text-align: center;">-</div>',
				isDraft,
				requiredFieldsFilled,
			);
		//} else if (field == '%%CREDITORS_FIRST_MEETING_SOLUTIONS%%') {
		//	templates = transformOtherFields(
		//		templates,
		//		'creditors_first_meeting_solutions',
		//		isDraft ? currentData.creditorsFirstMeetingSolutions : currentData.creditorsFirstMeetingSolutions || '<div style="width: 100%; text-align: center;">-</div>',
		//		isDraft,
		//		requiredFieldsFilled,
		//	);
		} else if (field == '%%CREDITORS_FIRST_MEETING_ARBITR_PROPOSALS%%') {
			templates = transformOtherFields(
				templates,
				'creditors_first_meeting_arbitr_proposals',
				isDraft ? currentData.creditorsFirstMeetingArbitrProposals : currentData.creditorsFirstMeetingArbitrProposals || '<div style="width: 100%; text-align: center;">-</div>',
				isDraft,
				requiredFieldsFilled,
			);
		} else if (/%%ASSETS.*?%%/.test(field)) {
			let assets = { ...allPossessions, other: allPossessions.other.filter(procession => !procession.onlyResidence) };
			templates = transformPersonAssetsTable(templates, assets);
		} else if (/%%LIABILITIES.*?%%/.test(field)) {
			templates = transformPersonLiabilitiesTable(templates, creditorsRequirements.allBesidesExcluded, undeclaredDebts);
		} else if (/%%RESPONSIBILITIES.*?%%/.test(field)) {
			templates = transformPersonResponsibilitiesTable(templates, creditorsRequirements.allBesidesExcluded, undeclaredDebts);
		} else if (/%%REALTY.*?%%/.test(field)) {
			const possessions = [
				...allPossessions.possessionsNoPledgeSubjectsLand,
				...allPossessions.possessionsNoPledgeSubjectsResidential,
				...allPossessions.possessionsNoPledgeSubjectsNonResidential,
				...allPossessions.possessionsPledgeSubjectsLand,
				...allPossessions.possessionsPledgeSubjectsResidential,
				...allPossessions.possessionsPledgeSubjectsNonResidential,
			];
			templates = transformPersonPossessionsTable(templates, 'REALTY', possessions);
		} else if (/%%MOVABLES.*?%%/.test(field)) {
			const possessions = [...allPossessions.possessionsNoPledgeSubjectsTransport, ...allPossessions.possessionsPledgeSubjectsTransport];
			templates = transformPersonPossessionsTable(templates, 'MOVABLES', possessions);
		} else if (/%%SHARES.*?%%/.test(field)) {
			const possessions = [...allPossessions.possessionsNoPledgeSubjectsShare, ...allPossessions.possessionsPledgeSubjectsShare];
			templates = transformPersonPossessionsTable(templates, 'SHARES', possessions);
		} else if (/%%OTHER_POSSESSIONS.*?%%/.test(field)) {
			const possessions = [...allPossessions.possessionsNoPledgeSubjectsOther, ...allPossessions.possessionsPledgeSubjectsOther];
			templates = transformPersonPossessionsTable(templates, 'OTHER_POSSESSIONS', possessions);
		} else if (/%%BANK_ACCOUNTS((?!_LIST).)*?%%/.test(field)) {
			templates = transformPersonPossessionsTable(templates, 'BANK_ACCOUNTS', transmitter.bankAccounts);
		} else if (/%%INCOME_SOURCES.*?%%/.test(field)) {
			templates = transformIncomeSourcesTable(templates, transmitter.incomeSources, isDraft);
		}
	}
	fields = [...new Set(templates.preview.match(/%%[A-Z0-9_]*%%/gm) || [])]
	//	console.log(`fields 1 (${fields.length}): ` + fields)

	// TODO: стремимся избавиться от arbitrary_text
	for (let key of ['arbitrary_text']) {
	// for (let key of ['analisys_conclusion', 'arbitrary_text']) {
		let value = substitutions[key];
		if (key == 'arbitrary_text') {
			value = isPresent(substitutions[key]) ? substitutions[key] : substitutions['dct_arbitrary_text'];
		}
		templates = transformArbitraryText(templates, key, value, isDraft, requiredFieldsFilled[key]);
	}

	fields = [...new Set(templates.preview.match(/%%[A-Z0-9_]*%%/gm) || [])]
	//	console.log(`fields 2 (${fields.length}): ` + fields)

	const substitutionsKeys = Object.keys(substitutions);
	const derivedDataKeys = Object.keys(derivedData).map(key => Case.snake(key));

	//	console.log('keys: ' + keys)

	for (let key of keys) {
		if (key.endsWith('surname')) {
			templates = transformSurnameFields(templates, key, substitutions, isDraft, requiredFieldsFilled);
		}
		if (key.startsWith('registrar')) {
			templates = transformRegistrarFields(templates, key, substitutions, isDraft, requiredFieldsFilled);
		}
		if (['obtaining_method', 'recipient_of_accounting_statement'].includes(key)) {
			templates = transformObtainingMethodFields(templates, key, substitutions[key]);
		}
		if (/bank_accounts/.test(key) && /(BANK_ACCOUNTS_LIST%%|BANK_ACCOUNTS_INLINE_LIST%%|%%MAIN_BANK_ACCOUNT%%)/.test(templates.preview)) {
			templates = insertBankAccounts(templates, key, substitutions, isDraft, transmitter);
		}
		if (/judicial_act_date/.test(key)) {
			key = 'judicial_act_date';
		}

		// эти 3 строки для сохранения fieldText равным нулю, простое || трактует 0 как false
		let fieldText = currentData[Case.camel(key)];
		if (substitutionsKeys.includes(key) && !fieldText && fieldText !== 0) {
			fieldText = substitutions[key];
		}
		if (derivedDataKeys.includes(key) && !fieldText && fieldText !== 0) {
			fieldText = derivedData[Case.camel(key)];
		}

		if (/percent/.test(key) || /external_factor_/.test(key) || /internal_factor_/.test(key)) {
			templates = transformPercentFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
		}
		if (
			/(amount|balance|monthly_expenses_of_debtor_|debt_to_creditors_from_statement_of_debtor|living_wage_for)/.test(key) ||
			['book_property_value', 'market_property_value', 'monthly_expenses', 'debt_to_creditors_from_statement_of_receivable', 'free_funds_of_bankrupt'].includes(
				key,
			)
		) {
			templates = transformAmountFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
		}
		if (key == 'meeting_questions') {
			templates = transformMeetingQuestions(templates, isDraft ? fieldText : substitutions[key]);
			continue;
		}
		if (key == 'meeting_solutions') {
			templates = transformMeetingSolutions(templates, isDraft ? fieldText : substitutions[key]);
			continue;
		}
		if (
			[
				'prolongations',
				'prolongations_resolution',
				'renewal_period',
				'completion_date_after_renewal',
				'completion_date_after_renewal_or_completion_date',
			].includes(key)
		) {
			fieldText = transmitter.procedure.prolongations;
		}
		if (['arbitr_manager_insurance_orgs', 'arbitr_manager_insurance_policies'].includes(key)) {
			const procedure = transmitter.procedure;
			const introductionDates = [procedure.resolution_introduction_date, procedure.introduction_date].filter(i => i);
			const dateStart = isPresent(introductionDates) ? introductionDates.reduce((min, c) => (c < min ? c : min)) : '2000-01-01';
			const completions = [procedure.completion_date, ...toArray(procedure.prolongations).map(p => p.completion_date)].filter(i => i);
			const dateFinish = isPresent(completions) ? completions.reduce((max, c) => (c > max ? c : max)) : '2100-12-31';

			fieldText = toArray(transmitter.insurancePolicies).filter(
				p =>
					p.proprietor_type == 'ArbitrManager' &&
					((isPresent(p.date_start) ? moment(p.date_start).isBetween(dateStart, dateFinish, undefined, '[]') : true) ||
						(isPresent(p.date_finish) ? moment(p.date_finish).isBetween(dateStart, dateFinish, undefined, '[]') : true) ||
						(isPresent(p.date_start) && isPresent(p.date_finish)
							? moment(dateStart).isBetween(p.date_start, p.date_finish, undefined, '[]') ||
								moment(dateFinish).isBetween(p.date_start, p.date_finish, undefined, '[]')
							: true)),
			);
		}

		templates = transformUniqueFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
		templates = transformOtherFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
		templates = transformOptionalParagraph(templates, substitutions.insert_first_paragraph);
	}

	fields = [...new Set(templates.preview.match(/%%[A-Z0-9_]*%%/gm) || [])]
	console.log(`fields 3 (${fields.length}): ` + fields)

	// обработка пустых полей. нужна ли вообще? пустоты можно по ходу заполнять.
	templates = clearFields(templates, isDraft);
	console.log('|||||==================== ! END clearFields ! ====================|||||')

	// используется в редакторах документов собраний, см. там
	pdfDctData.update(() => templates.pdfData);

	return templates;
};
