<!-- @format -->
<script>
	import { createEventDispatcher, onMount, tick } from 'svelte';
	import { fetchGet, fetchPost } from '~/js/utils/fetch_helpers';
	import { transmitter } from '~/js/global_stores/base';
	import { requiredFieldsFilled, pdfDctData } from '~/svelte/dcts/stores/stores';
	import { isPresent, createObjectURL, extractFilename } from '~/js/utils/tools';
	import FormFields from '~/svelte/dcts/form_fields.svelte';
	import Template from './template.svelte';
	import ErrorMessage from '~/svelte/components/message.svelte';
	import printJS from 'print-js';
	import fileDownload from 'js-file-download';
	import PrintIcon from '~/svelte/_shared/print_icon.svelte';
	import DownloadIcon from '~/svelte/_shared/download_icon.svelte';
	import { meetingCreditorData, meetingWorkerData } from '~/svelte/_shared/meeting_bulletin';
	import SwitchBtn from '~/svelte/components/ui/switch_btn.svelte';

	const dispatch = createEventDispatcher();

	export let params = {};
	export const reload = () => {
		refresh = false;
		load()
			.then(result => {
				bulletins = result.rows;
				refresh = true;
			})
			.catch(error => (errors = error));
	};
	export const refreshDocument = () => tick().then(() => changeCurrentDocument());

	let selected;
	let selectedBulletin;
	let processing = false;
	let refresh = false;
	let errors = [];
	let bulletins = [];
	let switchValue;

	const changeCurrentDocument = () => {
		if (isPresent(bulletins) && isPresent(selected)) {
			selectedBulletin = bulletins.find(e => e.id == selected);
			refreshCurrentDocument();
		}
	};

	const updateCurrentDocument = ({ detail: d }) => {
		selectedBulletin.dct = d.dct;
		refreshCurrentDocument();
	};

	const refreshCurrentDocument = () => {
		if (selectedBulletin) {
			selectedBulletin.dct.substitutions = { ...selectedBulletin.dct.substitutions, ...initCurrentDocument() };
			$requiredFieldsFilled = {};
			$transmitter.dct = selectedBulletin.dct;
		}
	};

	const initCurrentDocument = () => {
		const question = $transmitter.meeting.questions.find(e => e.id == selectedBulletin.question_id);
		const participant = $transmitter.meeting.participants.find(e => e.id == selectedBulletin.participant_id);

		if ($transmitter.meeting.kind == 'creditor') {
			return meetingCreditorData(question, participant);
		} else if ($transmitter.meeting.kind == 'worker') {
			return meetingWorkerData(question, participant);
		}
	};

	const dowloadDocument = () => {
		processing = true;

		fetchPost(
			'/api/private/meetings/' + $transmitter.meeting.id + '/download_bulletin',
			{ bulletin_id: selected, pdf_data: $pdfDctData },
			{ response_type: 'blob' },
		)
			.then(result => {
				result.blob().then(blob => {
					fileDownload(blob, extractFilename(result));
					processing = false;
				});
			})
			.catch(error => {
				errors = error;
				processing = false;
			});
	};

	const printDocument = () => {
		processing = true;

		fetchPost(
			'/api/private/meetings/' + $transmitter.meeting.id + '/download_bulletin',
			{ bulletin_id: selected, pdf_data: $pdfDctData },
			{ response_type: 'blob' },
		)
			.then(result => {
				result.blob().then(blob => {
					printJS({ printable: createObjectURL(blob), type: 'pdf', showModal: true });
					processing = false;
				});
			})
			.catch(error => {
				errors = error;
				processing = false;
			});
	};

	const load = () => fetchGet('/api/private/meetings/' + $transmitter.meeting.id + '/meeting_bulletins', params);

	onMount(() => {
		refresh = false;
		load()
			.then(result => {
				bulletins = result.rows;
				if (bulletins.length) {
					selected = bulletins[0].id;
				} else {
					$requiredFieldsFilled = {};
					$transmitter.dct = undefined;
				}
				refresh = true;
			})
			.catch(error => (errors = error));
	});

	$: changeCurrentDocument(selected, bulletins);
</script>

{#if isPresent(bulletins)}
	<button class="back-to-list btn btn-sm btn-outline-secondary mb-2" style="font-size: inherit;" on:click={() => dispatch('close')}>
		<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24">
			<path d="m12.707 7.707-1.414-1.414L5.586 12l5.707 5.707 1.414-1.414L8.414 12z"></path>
			<path d="M16.293 6.293 10.586 12l5.707 5.707 1.414-1.414L13.414 12l4.293-4.293z"></path>
		</svg>
		<span style="margin-left: -5px;">К списку</span>
	</button>
	<div class="w-100 overflow-auto">
		<div class="btn-group btn-group-toggle m-t-none" data-toggle="buttons">
			{#each bulletins as r (r.id)}
				<label class:active={selected == r.id} class="btn btn-sm btn-outline-secondary btn-document mb-2 cursor_pointer">
					<input type="radio" bind:group={selected} value={r.id} />
					{r.name}
				</label>
			{/each}
		</div>
	</div>
{/if}
<div class="document-form">
	<div class="toolbar">
		<div class="flex-slot">
			<ul class="nav navbar-top-links tools-bar flex-shrink-slot"></ul>
			<ul class="nav navbar-top-links tools-bar flex-grow-slot">
				{#if processing}
					<span class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
				{:else}
					<li class="m-l-sm">
						<span style="cursor: pointer;" role="button" tabindex="0" on:click={dowloadDocument} on:keypress|stopPropagation>
							<DownloadIcon />
						</span>
					</li>
					<li class="m-l-sm m-r-xs">
						<span style="cursor: pointer;" role="button" tabindex="0" on:click={printDocument} on:keypress|stopPropagation>
							<PrintIcon />
						</span>
					</li>
				{/if}
			</ul>
		</div>
	</div>
	<div class="content-body footer-margin">
		<div class="row" style="height: calc(100vh - 92px); margin-right: 0;">
			<div class="col-sm-5" class:hidden-mobile={!switchValue} style="overflow: auto; height: 100%; padding-right: 0;">
				<div class="overlay">
					<ErrorMessage message={errors} on:click={() => (errors = [])} />
					{#if refresh}
						<FormFields on:change on:update={updateCurrentDocument} />
					{:else}
						загружается...
					{/if}
				</div>
			</div>
			<div class="col-sm-7" class:hidden-mobile={switchValue} style="overflow: auto; height: 100%; padding-right: 0;">
				<div class="preview">
					<div class="preview__page">
						{#if refresh}
							<Template />
						{:else}
							загружается...
						{/if}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<SwitchBtn bind:switchValue />

<style lang="scss">
	.document-form {
		width: 100%;

		.toolbar {
			border-bottom: 1px solid #e7eaec;
			padding-bottom: 10px;
			padding-left: 15px;
			margin-left: -15px;
		}
		.content-body {
			background-color: #f3f3f4;
			.overlay,
			.preview {
				border-left: none;
				border-top: none;
			}
		}
	}
	.preview {
		flex-direction: column;
		align-items: center;
		color: #000;
		box-shadow:
			0 2px 2px 0 rgba(0, 0, 0, 0.14),
			0 3px 1px -2px rgba(0, 0, 0, 0.2),
			0 1px 5px 0 rgba(0, 0, 0, 0.12);
		border: 1px solid #e7eaec;
	}
	.preview__page {
		--aspect-ratio: 210 / 297;
		box-sizing: border-box;
		background-color: #fff;
		padding: 7.14% 7.14% 7.14% 9.52%;
	}
	/*.download-icon {
    padding: 10px;
    min-height: unset;
    fill: #1ab394;
    &:hover {
      fill: #fff;
    }
  }*/
	.back-to-list:hover {
		svg {
			fill: white;
		}
	}
</style>
